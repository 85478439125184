import { EventEmitter, ListenerFn } from "eventemitter3";

import { Barcode } from "./barcode";
import { BrowserHelper } from "./browserHelper";
import { EngineLoader } from "./engineLoader";
import { ScanSettings } from "./scanSettings";
import { EngineSentMessageData, EngineWorker, engineWorkerBlob } from "./workers/engineWorker";

type EventName = "blurryTablesUpdate";

class BlurryRecognitionPreloaderEventEmitter extends EventEmitter<EventName> {}

export class BlurryRecognitionPreloader {
  private static readonly writableDataPath: string = "/scandit_sync_folder_preload";
  private static readonly fsObjectStoreName: string = "FILE_DATA";
  // From AndroidLowEnd
  private static readonly blurryTableFiles: string[] = [
    "/0ae170296d3653ad308e7fa192d42fb6.scandit", // ean13, ean8, upca, upce
    "/1a3f08f42d1332344e3cebb5c53d9837.scandit", // code39
    "/32e564a3408a1555c8e1c437fee00d36.scandit", // code39
    "/3d90c055e483d26cc356c4a9e1b1fb37.scandit", // code39
    "/4243724f7555e82c259850107c30914f.scandit", // itf
    "/58c55d55c191d83754ff25398170a396.scandit", // code128
    "/59a53ea1435408779834719fa6c2cabd.scandit", // msi-plessey
    "/59c85c98c5674dd1072254ea6bd6ef92.scandit", // itf
    "/5f91576bc7215e09de2c145cccca50de.scandit", // code93
    "/6fa564c6d98a4cf360aead27987f9546.scandit", // msi-plessey
    "/76ca9155b19b81b4ea4a209c9c2154a4.scandit", // itf
    "/89dfec6b19b94e2bd9459388c7d2fefb.scandit", // ean13, ean8, upca, upce
    "/98908cb667cf64cf863486b6a7aafe8b.scandit", // code128
    "/cd5894907b6dd4d3ab237f353db43625.scandit", // msi-plessey
    "/e078b48a2b083e551246567e8cdf1b9c.scandit", // code93
    "/e171da0d56d58dc63b105a2f4dc5dce0.scandit", // code128
    "/e4d5141cd8ed672df64dca4f0bd1709e.scandit", // ean13, ean8, upca, upce
    "/eadf9b9d40ca243665e4ee7cbd7ba109.scandit", // code93
  ].map((path) => {
    return `${BlurryRecognitionPreloader.writableDataPath}${path}`;
  });
  private static readonly availableBlurryRecognitionSymbologies: Set<Barcode.Symbology> = new Set([
    Barcode.Symbology.EAN13, // Shared with UPCA
    Barcode.Symbology.EAN8,
    Barcode.Symbology.CODE39,
    Barcode.Symbology.CODE128,
    Barcode.Symbology.CODE93,
    Barcode.Symbology.INTERLEAVED_2_OF_5,
    Barcode.Symbology.MSI_PLESSEY,
    Barcode.Symbology.UPCA, // Shared with EAN13
    Barcode.Symbology.UPCE,
  ]);

  private readonly eventEmitter: BlurryRecognitionPreloaderEventEmitter = new EventEmitter();
  private readonly preload: boolean;

  private queuedBlurryRecognitionSymbologies: Barcode.Symbology[] = Array.from(
    BlurryRecognitionPreloader.availableBlurryRecognitionSymbologies.values()
  );
  private readyBlurryRecognitionSymbologies: Set<Barcode.Symbology> = new Set();
  private engineWorker: EngineWorker;

  private constructor(preload: boolean) {
    this.preload = preload;
  }

  public static create(preload: boolean): Promise<BlurryRecognitionPreloader> {
    if (preload) {
      // Edge currently doesn't support IndexedDB in blob Web Workers so data wouldn't be persisted,
      // hence it would be useless to preload blurry recognition as data couldn't be saved.
      const browserName: string | undefined = BrowserHelper.userAgentInfo.getBrowser().name;
      if (browserName != null && browserName.includes("Edge")) {
        const worker: Worker = new Worker(
          URL.createObjectURL(
            new Blob([`(${BlurryRecognitionPreloader.workerIndexedDBSupportTestFunction.toString()})()`], {
              type: "text/javascript",
            })
          )
        );

        return new Promise((resolve) => {
          worker.onmessage = (message) => {
            worker.terminate();
            resolve(new BlurryRecognitionPreloader(message.data));
          };
        });
      }
    }

    return Promise.resolve(new BlurryRecognitionPreloader(preload));
  }

  // istanbul ignore next
  private static workerIndexedDBSupportTestFunction(): void {
    try {
      indexedDB.deleteDatabase("scandit_indexeddb_support_test");
      // @ts-ignore
      postMessage(true);
    } catch (error) {
      // @ts-ignore
      postMessage(false);
    }
  }

  public prepareBlurryTables(): void {
    (this.preload ? this.checkBlurryTablesAlreadyAvailable() : Promise.resolve(true))
      .then((alreadyAvailable) => {
        if (alreadyAvailable) {
          this.queuedBlurryRecognitionSymbologies = [];
          this.readyBlurryRecognitionSymbologies = new Set(
            BlurryRecognitionPreloader.availableBlurryRecognitionSymbologies
          );
          this.eventEmitter.emit("blurryTablesUpdate", new Set(this.readyBlurryRecognitionSymbologies));
        } else {
          this.engineWorker = new Worker(URL.createObjectURL(engineWorkerBlob));
          this.engineWorker.onmessage = this.engineWorkerOnMessage.bind(this);
          EngineLoader.load(this.engineWorker, true, true);
        }
      })
      .catch(console.error);
  }

  public on(eventName: EventName, listener: ListenerFn): void {
    // istanbul ignore else
    if (eventName === "blurryTablesUpdate") {
      if (
        this.readyBlurryRecognitionSymbologies.size ===
        BlurryRecognitionPreloader.availableBlurryRecognitionSymbologies.size
      ) {
        listener(this.readyBlurryRecognitionSymbologies);
      } else {
        this.eventEmitter.on(eventName, listener);
      }
    }
  }

  public updateBlurryRecognitionPriority(scanSettings: ScanSettings): void {
    const newQueuedBlurryRecognitionSymbologies: Barcode.Symbology[] = this.queuedBlurryRecognitionSymbologies.slice();
    this.getEnabledSymbologies(scanSettings).forEach((symbology) => {
      const symbologyQueuePosition: number = newQueuedBlurryRecognitionSymbologies.indexOf(symbology);
      if (symbologyQueuePosition !== -1) {
        newQueuedBlurryRecognitionSymbologies.unshift(
          newQueuedBlurryRecognitionSymbologies.splice(symbologyQueuePosition, 1)[0]
        );
      }
    });
    this.queuedBlurryRecognitionSymbologies = newQueuedBlurryRecognitionSymbologies;
  }

  public isBlurryRecognitionAvailable(scanSettings: ScanSettings): boolean {
    const enabledBlurryRecognitionSymbologies: Barcode.Symbology[] = this.getEnabledSymbologies(scanSettings);

    return enabledBlurryRecognitionSymbologies.every((symbology) => {
      return this.readyBlurryRecognitionSymbologies.has(symbology);
    });
  }

  public getEnabledSymbologies(scanSettings: ScanSettings): Barcode.Symbology[] {
    return Array.from(BlurryRecognitionPreloader.availableBlurryRecognitionSymbologies.values()).filter((symbology) => {
      return scanSettings.isSymbologyEnabled(symbology);
    });
  }

  private createNextBlurryTableSymbology(): void {
    let symbology: Barcode.Symbology | undefined;
    do {
      symbology = this.queuedBlurryRecognitionSymbologies.shift();
    } while (symbology != null && this.readyBlurryRecognitionSymbologies.has(symbology));
    // istanbul ignore else
    if (symbology != null) {
      this.engineWorker.postMessage({
        type: "create-blurry-table",
        symbology,
      });
    }
  }

  private checkBlurryTablesAlreadyAvailable(): Promise<boolean> {
    return new Promise((resolve) => {
      const openDbRequest: IDBOpenDBRequest = indexedDB.open(BlurryRecognitionPreloader.writableDataPath);
      function handleErrorOrNew(this: IDBOpenDBRequest | IDBTransaction | IDBRequest | { error: Error }): void {
        openDbRequest?.result?.close();
        // this.error
        resolve(false);
      }

      openDbRequest.onupgradeneeded = () => {
        try {
          openDbRequest.result.createObjectStore(BlurryRecognitionPreloader.fsObjectStoreName);
        } catch (error) {
          // Ignored
        }
      };
      openDbRequest.onsuccess = () => {
        try {
          const transaction: IDBTransaction = openDbRequest.result.transaction(
            BlurryRecognitionPreloader.fsObjectStoreName,
            "readonly"
          );
          transaction.onerror = handleErrorOrNew;
          const storeKeysRequest: IDBRequest<IDBValidKey[]> = transaction
            .objectStore(BlurryRecognitionPreloader.fsObjectStoreName)
            .getAllKeys();
          storeKeysRequest.onsuccess = () => {
            openDbRequest.result.close();
            if (
              BlurryRecognitionPreloader.blurryTableFiles.every((file) => {
                return storeKeysRequest.result.indexOf(file) !== -1;
              })
            ) {
              return resolve(true);
            } else {
              return resolve(false);
            }
          };
          storeKeysRequest.onerror = handleErrorOrNew;
        } catch (error) {
          handleErrorOrNew.call({ error });
        }
      };
      openDbRequest.onblocked = openDbRequest.onerror = handleErrorOrNew;
    });
  }

  private engineWorkerOnMessage(ev: MessageEvent): void {
    const data: EngineSentMessageData = ev.data;

    // istanbul ignore else
    if (data[1] != null) {
      switch (data[0]) {
        case "license-key-features":
          this.createNextBlurryTableSymbology();
          break;
        case "create-blurry-table-result":
          this.readyBlurryRecognitionSymbologies.add(data[1]);
          if (data[1] === Barcode.Symbology.EAN13) {
            this.readyBlurryRecognitionSymbologies.add(Barcode.Symbology.UPCA);
          } else if (data[1] === Barcode.Symbology.UPCA) {
            this.readyBlurryRecognitionSymbologies.add(Barcode.Symbology.EAN13);
          }
          this.eventEmitter.emit("blurryTablesUpdate", new Set(this.readyBlurryRecognitionSymbologies));
          if (
            this.readyBlurryRecognitionSymbologies.size ===
            BlurryRecognitionPreloader.availableBlurryRecognitionSymbologies.size
          ) {
            this.engineWorker.terminate();
          } else {
            this.createNextBlurryTableSymbology();
          }
          break;
        // istanbul ignore next
        default:
          break;
      }
    }
  }
}
