import { deviceId, scanditEngineLocation, userLicenseKey } from "../index";
import { BrowserHelper } from "./browserHelper";
import { EngineWorker, engineWorkerBlob } from "./workers/engineWorker";

export class EngineLoader {
  private preloadedEngineWorker?: EngineWorker;
  private preloadedEngineWorkerAvailable: boolean;

  constructor(preload: boolean) {
    if (preload) {
      this.preloadedEngineWorker = new Worker(URL.createObjectURL(engineWorkerBlob));
      EngineLoader.load(this.preloadedEngineWorker);
    }
    this.preloadedEngineWorkerAvailable = preload;
  }

  public static load(engineWorker: EngineWorker, preload: boolean = false, delayedRegistration: boolean = false): void {
    engineWorker.postMessage({
      type: "load-library",
      deviceId,
      libraryLocation: scanditEngineLocation,
      path: self.location.pathname,
      preload,
      deviceModelName: BrowserHelper.userAgentInfo.getDevice().model,
    });
    engineWorker.addEventListener(
      "message",
      (event) => {
        // istanbul ignore else
        if (event.data[0] === "status" && event.data[1] === "ready") {
          engineWorker.postMessage({
            type: "create-context",
            licenseKey: userLicenseKey,
            delayedRegistration,
          });
        }
      },
      { once: true }
    );
  }

  public getEngineWorker(): EngineWorker {
    if (this.preloadedEngineWorkerAvailable && this.preloadedEngineWorker != null) {
      this.preloadedEngineWorkerAvailable = false;

      return this.preloadedEngineWorker;
    } else {
      return new Worker(URL.createObjectURL(engineWorkerBlob));
    }
  }

  public returnEngineWorker(engineWorker: EngineWorker): void {
    if (this.preloadedEngineWorker == null) {
      this.preloadedEngineWorker = engineWorker;
    }
    if (this.preloadedEngineWorker === engineWorker) {
      this.preloadedEngineWorker.onmessage = null;
      this.preloadedEngineWorker.postMessage({
        type: "reset",
      });
      this.preloadedEngineWorkerAvailable = true;
    } else {
      engineWorker.terminate();
    }
  }
}
