import { BarcodeEncodingRange } from "./barcodeEncodingRange";
import { Quadrilateral } from "./quadrilateral";

/**
 * A barcode result.
 */
export interface Barcode {
  /**
   * The symbology type.
   */
  readonly symbology: Barcode.Symbology;
  /**
   * The data encoded in the barcode interpreted as a UTF-8 string.
   * If the raw data is not a valid UTF-8 string, this field will be an empty string.
   */
  readonly data: string;
  /**
   * The raw data encoded in the barcode, given as an array of bytes.
   * To interpret this correctly you may have to use the information contained in encodingArray.
   */
  readonly rawData: Uint8Array;
  /**
   * The location of the barcode.
   */
  readonly location: Quadrilateral;
  /**
   * Whether the barcode is part of a composite code.
   */
  readonly compositeFlag: Barcode.CompositeFlag;
  /**
   * Whether the barcode is a GS1 data carrier.
   */
  readonly isGs1DataCarrier: boolean;
  /**
   * The data encoding of the data in the barcode, given as an array of encoding ranges.
   */
  readonly encodingArray: BarcodeEncodingRange[];
}

/**
 * @hidden
 */
export type BarcodeWASMResult = {
  readonly symbology: Barcode.Symbology;
  readonly rawData: number[];
  readonly location: number[][];
  readonly compositeFlag: Barcode.CompositeFlag;
  readonly isGs1DataCarrier: boolean;
  readonly encodingArray: BarcodeEncodingRange[];
  readonly isRecognized: boolean;
};

export namespace Barcode {
  // Deprecated but useful function for easy UTF-8 handling
  /**
   * @hidden
   */
  declare function escape(s: string): string;

  /**
   * @hidden
   *
   * Create a [[Barcode]] object from a partial object returned by the external Scandit Engine library.
   * The *rawData* and *data* fields are computed and stored.
   *
   * @param result The barcode result coming from the external Scandit Engine library.
   * @returns The generated [[Barcode]] object.
   */
  export function createFromWASMResult(result: BarcodeWASMResult): Barcode {
    let decodedData: string;
    try {
      decodedData = decodeURIComponent(escape(String.fromCharCode.apply(null, result.rawData)));
    } catch {
      decodedData = "";
    }

    return {
      symbology: result.symbology,
      data: decodedData,
      rawData: new Uint8Array(result.rawData),
      location: {
        topLeft: { x: result.location[0][0], y: result.location[0][1] },
        topRight: { x: result.location[1][0], y: result.location[1][1] },
        bottomRight: { x: result.location[2][0], y: result.location[2][1] },
        bottomLeft: { x: result.location[3][0], y: result.location[3][1] },
      },
      compositeFlag: result.compositeFlag,
      isGs1DataCarrier: result.isGs1DataCarrier,
      encodingArray: result.encodingArray,
    };
  }

  /**
   * Barcode symbology type.
   */
  export enum Symbology {
    AZTEC = "aztec",
    CODABAR = "codabar",
    CODE11 = "code11",
    CODE128 = "code128",
    CODE25 = "code25",
    CODE32 = "code32",
    CODE39 = "code39",
    CODE93 = "code93",
    DATA_MATRIX = "data-matrix",
    DOTCODE = "dotcode",
    EAN13 = "ean13",
    EAN8 = "ean8",
    FIVE_DIGIT_ADD_ON = "five-digit-add-on",
    GS1_DATABAR = "databar",
    GS1_DATABAR_EXPANDED = "databar-expanded",
    GS1_DATABAR_LIMITED = "databar-limited",
    IATA_2_OF_5 = "iata2of5",
    INTERLEAVED_2_OF_5 = "itf",
    KIX = "kix",
    LAPA4SC = "lapa4sc",
    MAXICODE = "maxicode",
    MICRO_PDF417 = "micropdf417",
    MICRO_QR = "microqr",
    MSI_PLESSEY = "msi-plessey",
    PDF417 = "pdf417",
    QR = "qr",
    RM4SCC = "rm4scc",
    TWO_DIGIT_ADD_ON = "two-digit-add-on",
    UPCA = "upca",
    UPCE = "upce",
  }

  /**
   * Flags to hint that two codes form a composite code.
   */
  export enum CompositeFlag {
    /**
     * Code is not part of a composite code.
     */
    NONE = 0x0,
    /**
     * Code could be part of a composite code. This flag is set by linear (1D) symbologies that have
     * no composite flag support but can be part of a composite code like the EAN/UPC symbology family.
     */
    UNKNOWN = 0x1,
    /**
     * Code is the linear component of a composite code. This flag can be set by GS1 DataBar or GS1-128 (Code 128).
     */
    LINKED = 0x2,
    /**
     * Code is a GS1 Composite Code Type A (CC - A).This flag can be set by MicroPDF417 codes.
     */
    GS1_A = 0x4,
    /**
     * Code is a GS1 Composite Code Type B (CC-B). This flag can be set by MicroPDF417 codes.
     */
    GS1_B = 0x8,
    /**
     * Code is a GS1 Composite Code Type C (CC-C). This flag can be set by PDF417 codes.
     */
    GS1_C = 0x10,
  }

  // istanbul ignore next
  export namespace Symbology {
    /**
     * @hidden
     */
    // tslint:disable:no-unnecessary-qualifier
    const humanizedSymbologyNames: Map<Symbology, string> = new Map([
      [Symbology.AZTEC, "Aztec"],
      [Symbology.CODABAR, "Codabar"],
      [Symbology.CODE11, "Code 11"],
      [Symbology.CODE128, "Code 128"],
      [Symbology.CODE25, "Code 25"],
      [Symbology.CODE32, "Code 32"],
      [Symbology.CODE39, "Code 39"],
      [Symbology.CODE93, "Code 93"],
      [Symbology.DATA_MATRIX, "Data Matrix"],
      [Symbology.DOTCODE, "DotCode"],
      [Symbology.EAN13, "EAN-13"],
      [Symbology.EAN8, "EAN-8"],
      [Symbology.FIVE_DIGIT_ADD_ON, "Five-Digit Add-On"],
      [Symbology.GS1_DATABAR_EXPANDED, "GS1 DataBar Expanded"],
      [Symbology.GS1_DATABAR_LIMITED, "GS1 DataBar Limited"],
      [Symbology.GS1_DATABAR, "GS1 DataBar 14"],
      [Symbology.IATA_2_OF_5, "IATA 2 of 5"],
      [Symbology.INTERLEAVED_2_OF_5, "Interleaved 2 of 5"],
      [Symbology.KIX, "KIX"],
      [Symbology.LAPA4SC, "LAPA4SC"],
      [Symbology.MAXICODE, "MaxiCode"],
      [Symbology.MICRO_PDF417, "MicroPDF417"],
      [Symbology.MICRO_QR, "Micro QR"],
      [Symbology.MSI_PLESSEY, "MSI-Plessey"],
      [Symbology.PDF417, "PDF417"],
      [Symbology.QR, "QR"],
      [Symbology.RM4SCC, "RM4SCC"],
      [Symbology.TWO_DIGIT_ADD_ON, "Two-Digit Add-On"],
      [Symbology.UPCA, "UPC-A"],
      [Symbology.UPCE, "UPC-E"],
    ]);

    /**
     * @hidden
     */
    const jsonSymbologyNames: Map<Symbology, string> = new Map([
      [Symbology.AZTEC, "aztec"],
      [Symbology.CODABAR, "codabar"],
      [Symbology.CODE11, "code11"],
      [Symbology.CODE128, "code128"],
      [Symbology.CODE25, "code25"],
      [Symbology.CODE32, "code32"],
      [Symbology.CODE39, "code39"],
      [Symbology.CODE93, "code93"],
      [Symbology.DATA_MATRIX, "data-matrix"],
      [Symbology.DOTCODE, "dotcode"],
      [Symbology.EAN13, "ean13"],
      [Symbology.EAN8, "ean8"],
      [Symbology.FIVE_DIGIT_ADD_ON, "five-digit-add-on"],
      [Symbology.GS1_DATABAR_EXPANDED, "databar-expanded"],
      [Symbology.GS1_DATABAR_LIMITED, "databar-limited"],
      [Symbology.GS1_DATABAR, "databar"],
      [Symbology.IATA_2_OF_5, "iata2of5"],
      [Symbology.INTERLEAVED_2_OF_5, "itf"],
      [Symbology.KIX, "kix"],
      [Symbology.LAPA4SC, "lapa4sc"],
      [Symbology.MAXICODE, "maxicode"],
      [Symbology.MICRO_PDF417, "micropdf417"],
      [Symbology.MICRO_QR, "microqr"],
      [Symbology.MSI_PLESSEY, "msi-plessey"],
      [Symbology.PDF417, "pdf417"],
      [Symbology.QR, "qr"],
      [Symbology.RM4SCC, "rm4scc"],
      [Symbology.TWO_DIGIT_ADD_ON, "two-digit-add-on"],
      [Symbology.UPCA, "upca"],
      [Symbology.UPCE, "upce"],
    ]);
    // tslint:enable:no-unnecessary-qualifier

    /**
     * Get the humanized name of a symbology.
     *
     * @param symbology The symbology for which to retrieve the name.
     * @returns The humanized name of the symbology.
     */
    export function toHumanizedName(symbology: Symbology): string {
      return humanizedSymbologyNames.get(<Symbology>symbology.toLowerCase()) ?? "Unknown";
    }

    /**
     * Get the JSON key name of a symbology, used for JSON-formatted ScanSettings and Scandit Engine library.
     *
     * @param symbology The symbology for which to retrieve the name.
     * @returns The json key name of the symbology.
     */
    export function toJSONName(symbology: Symbology): string {
      return jsonSymbologyNames.get(<Symbology>symbology.toLowerCase()) ?? "unknown";
    }
  }
}
